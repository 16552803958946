import React, { useState, useEffect } from 'react';
import ReactFlow from 'react-flow-renderer';
import './FlowDiagram.css';

const PLSFlowDiagram = ({ index }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getStylesForSize = () => {
    if (windowSize.width <= 480) {
      return {
        nodeSize: { width: 25, height: 25 },
        fontSize: 9,
        lpSize: { width: 60, height: 11 },
        lpFontSize: 9,
        positions: {
          usd: { x: 90, y: 60 },
          plsStables: { x: 140, y: 60 },
          pls: { x: 230, y: 60 },
        },
      };
    } else if (windowSize.width <= 768) {
      return {
        nodeSize: { width: 32, height: 32 },
        fontSize: 13,
        lpSize: { width: 90, height: 30 },
        lpFontSize: 13,
        positions: {
          usd: { x: 130, y: 100 },
          plsStables: { x: 210, y: 100 },
          pls: { x: 350, y: 100 },
        },
      };
    } else {
      return {
        nodeSize: { width: 40, height: 40 },
        fontSize: 14,
        lpSize: { width: 120, height: 40 },
        lpFontSize: 13,
        positions: {
          usd: { x: 180, y: 120 },
          plsStables: { x: 280, y: 120 },
          pls: { x: 460, y: 120 },
        },
      };
    }
  };

  const styles = getStylesForSize();

  const isNodeActive = (nodeId) => {
    const activeNodes = {
      0: ['usd', 'pls-stables', 'pls'],
    };
    return activeNodes[index]?.includes(nodeId) || false;
  };

  const getEdgeAnimation = (edgeIndex) => {
    const animatedEdges = {
      0: ['e1', 'e2'],
    };
    return animatedEdges[index]?.includes(edgeIndex) || false;
  };

  const nodes = [
    {
      id: 'usd',
      type: 'input',
      data: { label: '$' },
      position: styles.positions.usd,
      style: { 
        background: '#4CAF50', 
        color: 'black', 
        borderRadius: '50%', 
        width: styles.nodeSize.width, 
        height: styles.nodeSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.fontSize}px` 
      }
    },
    {
      id: 'pls-stables',
      data: { label: 'PLS_Stables LP' },
      position: styles.positions.plsStables,
      style: { 
        background: '#1e88e5', 
        color: 'white', 
        width: styles.lpSize.width, 
        height: styles.lpSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.lpFontSize}px`
      }
    },
    {
      id: 'pls',
      type: 'output',
      data: { label: 'PLS' },
      position: styles.positions.pls,
      style: { 
        border: '1px solid white', 
        color: 'black', 
        borderRadius: '50%', 
        width: styles.nodeSize.width, 
        height: styles.nodeSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.fontSize}px` 
      }
    },
  ].map(node => ({
    ...node,
    style: {
      ...node.style,
      background: isNodeActive(node.id) ? node.style.background : '#808080',
      color: isNodeActive(node.id) ? node.style.color : 'white',
      border: isNodeActive(node.id) ? node.style.border : '1px solid #808080',
    }
  }));

  const edges = [
    { id: 'e1', source: 'usd', target: 'pls-stables', type: 'smoothstep', animated: getEdgeAnimation('e1'), style: { stroke: getEdgeAnimation('e1') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e1') ? '#FFD700' : '#4CAF50' } },
    { id: 'e2', source: 'pls-stables', target: 'pls', type: 'smoothstep', animated: getEdgeAnimation('e2'), style: { stroke: getEdgeAnimation('e2') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e2') ? '#FFD700' : '#4CAF50' } },
  ].map(edge => ({
    ...edge,
    animated: getEdgeAnimation(edge.id),
    style: {
      ...edge.style,
      stroke: getEdgeAnimation(edge.id) ? '#FFD700' : '#808080',
    },
    markerEnd: {
      ...edge.markerEnd,
      color: getEdgeAnimation(edge.id) ? '#FFD700' : '#808080',
    }
  }));

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      zoomOnScroll={false}
      zoomOnPinch={false}
      zoomOnDoubleClick={false}
      panOnScroll={false}
      panOnDrag={false}
      nodesDraggable={false}
      style={{ width: '100%', height: '280px' }}
    />
  );
};

export default PLSFlowDiagram;
