import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Paper, Box, LinearProgress, Link } from '@mui/material';
import ReactFlow from 'react-flow-renderer';
import { styled } from '@mui/material/styles';
import './LPFlowView.css';
import PLSXFlowDiagram from './PLSXflow';
import PLSFlowDiagram from './PLSflow';
import INCFlowDiagram from './INCflow';
import HEXFlowDiagram from './HEXflow';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: '#1e1e1e', // Dark background
  color: '#ffffff', // White text
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#ffffff', // White text
  fontWeight: 'bold',
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: 'transparent',
  '& .MuiStepConnector-line': {
    display: 'none', // Hide the default connector line
  },
  '& .MuiStepLabel-label': {
    color: '#ffffff',
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: '#4CAF50', // Green for completed steps
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: '#4CAF50', // Green for active step
  },
  '& .MuiStepIcon-root': {
    color: '#666666', // Grey for upcoming steps
  },
  '& .MuiStepIcon-text': {
    fill: '#ffffff', // White text for step numbers
  },
}));

const StepProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: 'calc(100% - 40px)', // Adjust width to fit between steps
  height: 4,
  marginTop: '-14px',
  position: 'absolute',
  left: '20px', // Offset to start after the first step
  backgroundColor: '#666666',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#4CAF50',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4CAF50', // Green background
  color: '#ffffff', // White text
  '&:hover': {
    backgroundColor: '#45a049', // Slightly darker green on hover
  },
  '&:disabled': {
    backgroundColor: '#2c2c2c', // Dark grey when disabled
    color: '#666666', // Lighter grey text when disabled
  },
}));

const ContentHeading = styled(Typography)(({ theme }) => ({
  color: '#4CAF50',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderBottom: '1px solid #4CAF50',
}));

const StyledTable = styled('table')(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',
  marginBottom: theme.spacing(2),
  '& th, & td': {
    border: '1px solid #4CAF50',
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  '& th': {
    backgroundColor: '#2c2c2c',
    color: '#4CAF50',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '65%', // Set a fixed height for the content area
}));


const ContentBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: theme.spacing(2),
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: '#2c2c2c',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#4CAF50',
  },
}));

const LPFlowView = ({ data, onClose, inputs }) => {
  const selectedTickers = inputs.map(input => input.ticker);

  const [activeStep, setActiveStep] = useState(0);
  const [activeSubStep, setActiveSubStep] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    setActiveSubStep(0);
  }, [activeStep]);

  const handleNext = () => {
    const currentStep = steps[activeStep];
    if (currentStep.hasSubSteps && activeSubStep < currentStep.subSteps.length - 1) {
      setActiveSubStep(prevSubStep => prevSubStep + 1);
    } else {
      setActiveStep(prevStep => prevStep + 1);
      setActiveSubStep(0);
    }
  };

  const handleBack = () => {
    const currentStep = steps[activeStep];
    if (currentStep.hasSubSteps && activeSubStep > 0) {
      setActiveSubStep(prevSubStep => prevSubStep - 1);
    } else {
      setActiveStep(prevStep => prevStep - 1);
      setActiveSubStep(steps[activeStep - 1].hasSubSteps ? steps[activeStep - 1].subSteps.length - 1 : 0);
    }
  };

  const calculateChange = (start, end) => {
    const changeInX = end / start;
    const percentageChange = ((end - start) / start) * 100;
    return {
      x: changeInX.toFixed(2),
      percentage: percentageChange.toFixed(2)
    };
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getShortDescription = (ticker) => {
    return "To simulate purchases, we loop over the above route with purchase amounts that cause less than 0.16% slippage...";
  };

  const getLongDescription = (ticker) => {
    return (
      <>
        To simulate purchases, we loop over the above route with purchase amounts that cause less than 0.16% slippage. The above route is attempted by routers such as the one on PulseX, though this maybe a simplified version of it, it fits best in understanding how LP affects other tickers and create reflexive price-action. This is why you'll see the Final values in the next step will always show you price rise across all the tickers. This is essentially due to the PulseX MasterChef and their chosen LP farm strategy.
        <br /><br />
        Note that In case you chose multiple tickers, we route the purchases equally through all tickers you select.
      </>
    );
  };

  const steps = [
    {
      label: `Starting Values`,
      alternativeLabel: `Starting Values`,
      content: () => (
        <div>
          <ContentHeading>Initial Token Prices and LP Values</ContentHeading>
          <StyledTable>
            <thead>
              <tr>
                <th>Token</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PLS</td>
                <td>${parseFloat(data.originalPricesAndLPValues.pricePLS).toFixed(6)}</td>
              </tr>
              <tr>
                <td>PLSX</td>
                <td>${parseFloat(data.originalPricesAndLPValues.pricePLSX).toFixed(6)}</td>
              </tr>
              <tr>
                <td>INC</td>
                <td>${parseFloat(data.originalPricesAndLPValues.priceINC).toFixed(2)}</td>
              </tr>
              <tr>
                <td>HEX</td>
                <td>${parseFloat(data.originalPricesAndLPValues.priceHEX).toFixed(4)}</td>
              </tr>
            </tbody>
          </StyledTable>
          <StyledTable>
            <thead>
              <tr>
                <th>LP Pair</th>
                <th>Value (USD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PLS-INC</td>
                <td>${Math.round(parseFloat(data.originalPricesAndLPValues.lpValuePLS_INC.usd)).toLocaleString()}</td>
              </tr>
              <tr>
                <td>PLSX-INC</td>
                <td>${Math.round(parseFloat(data.originalPricesAndLPValues.lpValuePLSX_INC.usd)).toLocaleString()}</td>
              </tr>
              <tr>
                <td>PLS-PLSX</td>
                <td>${Math.round(parseFloat(data.originalPricesAndLPValues.lpValuePLS_PLSX.usd)).toLocaleString()}</td>
              </tr>
              <tr>
                <td>PLS-Stables</td>
                <td>${Math.round(parseFloat(data.originalPricesAndLPValues.lpValuePLS_Stables.usd)).toLocaleString()}</td>
              </tr>
              <tr>
                <td>PLS-HEX</td>
                <td>${Math.round(parseFloat(data.originalPricesAndLPValues.lpValuePLS_HEX.usd)).toLocaleString()}</td>
              </tr>
            </tbody>
          </StyledTable>
        </div>
      ),
    },
    ...selectedTickers.map(ticker => {
      const result = data.results.find(result => result.ticker === ticker);
      return {
        label: `${ticker} Purchase Route`,
        alternativeLabel: `${ticker} Purchase`,
        hasSubSteps: true,
        subSteps: result ? result.lpPurchases.map((lpPurchase, index) => ({
          content: () => {
            const progress = ((index + 1) / result.lpPurchases.length) * 100;
            return (
              <div>
                <ContentHeading>Route to purchase {ticker}</ContentHeading>
                <Typography>Step {index + 1} of {result.lpPurchases.length}</Typography>
                <StyledLinearProgress variant="determinate" value={progress} />
                <Box className="purchase-route-container">
                  <Box className={`purchase-route-diagram ${ticker.toLowerCase()}-diagram`}>
                    {ticker === 'PLSX' && <PLSXFlowDiagram index={index} />} 
                    {ticker === 'HEX' && <HEXFlowDiagram index={index} />}
                    {ticker === 'INC' && <INCFlowDiagram index={index} />}
                    {ticker === 'PLS' && <PLSFlowDiagram index={index} />}
                  </Box>
                  <Typography className="purchase-route-description">
                    {showFullDescription ? getLongDescription(ticker) : getShortDescription(ticker)}
                    <br />
                    <Link
                      component="button"
                      variant="body2"
                      onClick={toggleDescription}
                      className="show-more-link"
                    >
                      {showFullDescription ? "Show less" : "Show more"}
                    </Link>
                  </Typography>
                </Box>
              </div>
            );
          },
        })) : [],
      };
    }),
    {
      label: `Final Values`,
      alternativeLabel: `Final Values`,
      content: () => (
        <div>
          <ContentHeading>Final Token Prices and LP Values</ContentHeading>
          <StyledTable>
            <thead>
              <tr>
                <th>Token</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PLS</td>
                <td>${parseFloat(data.final_results.pricePLS).toFixed(6)}</td>
              </tr>
              <tr>
                <td>PLSX</td>
                <td>${parseFloat(data.final_results.pricePLSX).toFixed(6)}</td>
              </tr>
              <tr>
                <td>INC</td>
                <td>${parseFloat(data.final_results.priceINC).toFixed(2)}</td>
              </tr>
              <tr>
                <td>HEX</td>
                <td>${parseFloat(data.final_results.priceHEX).toFixed(4)}</td>
              </tr>
            </tbody>
          </StyledTable>
          <StyledTable>
            <thead>
              <tr>
                <th>LP Pair</th>
                <th>Value (USD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PLS-INC</td>
                <td>${Math.round(parseFloat(data.final_results.lpValuePLS_INC.usd)).toLocaleString()}</td>
              </tr>
              <tr>
                <td>PLSX-INC</td>
                <td>${Math.round(parseFloat(data.final_results.lpValuePLSX_INC.usd)).toLocaleString()}</td>
              </tr>
              <tr>
                <td>PLS-PLSX</td>
                <td>${Math.round(parseFloat(data.final_results.lpValuePLS_PLSX.usd)).toLocaleString()}</td>
              </tr>
              <tr>
                <td>PLS-Stables</td>
                <td>${Math.round(parseFloat(data.final_results.lpValuePLS_Stables.usd)).toLocaleString()}</td>
              </tr>
              <tr>
                <td>PLS-HEX</td>
                <td>${Math.round(parseFloat(data.final_results.lpValuePLS_HEX.usd)).toLocaleString()}</td>
              </tr>
            </tbody>
          </StyledTable>
        </div>
      ),
    },
  ];

  const currentStep = steps[activeStep];
  const currentContent = currentStep.hasSubSteps 
    ? currentStep.subSteps[activeSubStep].content 
    : currentStep.content;

  return (
    <div className="lp-flow-view">
      <StyledPaper className="lp-flow-view">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <StyledTypography variant="h4">LP Flow</StyledTypography>
          <StyledButton variant="outlined" onClick={onClose}>Close</StyledButton>
        </Box>
        <StyledStepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.alternativeLabel}</StepLabel>
              {index < steps.length - 1 && (
                <Box sx={{ width: '100%', position: 'absolute', left: '50%', top: '24px' }}>
                  <StepProgressBar
                    variant="determinate"
                    value={activeStep > index ? 100 : 0}
                  />
                </Box>
              )}
            </Step>
          ))}
        </StyledStepper>
        <StyledBox mt={4}>
          <ContentBox>
            <StyledPaper>
              {currentContent()}
            </StyledPaper>
          </ContentBox>
          <Box display="flex" justifyContent="space-between">
            <StyledButton
              onClick={handleBack}
              disabled={activeStep === 0 && activeSubStep === 0}
            >
              Back
            </StyledButton>
            <StyledButton
              onClick={handleNext}
              disabled={activeStep === steps.length - 1 && (!currentStep.hasSubSteps || activeSubStep === currentStep.subSteps.length - 1)}
            >
              {activeStep === steps.length - 1 && (!currentStep.hasSubSteps || activeSubStep === currentStep.subSteps.length - 1) ? 'Finish' : 'Next'}
            </StyledButton>
          </Box>
        </StyledBox>
      </StyledPaper>
    </div>
  );
};

// ... existing imports ...

// const LPFlowView = ({ data, onClose }) => {
//   return (
//     <div className="lp-flow-view">
//       <StyledPaper className="lp-flow-view">
//         <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//           <StyledTypography variant="h4">LP Flow</StyledTypography>
//           <StyledButton variant="outlined" onClick={onClose}>Close</StyledButton>
//         </Box>
//         <Box 
//           display="flex" 
//           justifyContent="center" 
//           alignItems="center" 
//           height="500px"
//           sx={{
//             backgroundColor: 'rgba(128, 128, 128, 0.3)',
//             borderRadius: '4px',
//           }}
//         >
//           <Typography variant="h5" sx={{ color: '#999' }}>
//             Under construction
//           </Typography>
//         </Box>
//       </StyledPaper>
//     </div>
//   );
// };

export default LPFlowView;