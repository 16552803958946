import React, { useState, useEffect } from 'react';
import ReactFlow from 'react-flow-renderer';

const LPFlowDiagram = ({ index }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getStylesForSize = () => {
    if (windowSize.width <= 480) {
      return {
        nodeSize: { width: 25, height: 25 },
        fontSize: 9,
        lpSize: { width: 60, height: 11 },
        lpFontSize: 9,
        positions: {
          usd: { x: 25, y: 80 },
          plsStables: { x: 60, y: 80 },
          pls: { x: 134.5, y: 80 },
          plsPlsx: { x: 117, y: 20 },
          plsx1: { x: 190, y: 20 },
          plsInc: { x: 117, y: 140 },
          inc: { x: 185, y: 140 },
          plsxInc: { x: 220, y: 141 },
          plsx2: { x: 290, y: 140 },
        },
      };
    } else if (windowSize.width <= 768) {
      return {
        nodeSize: { width: 32, height: 32 },
        fontSize: 13,
        lpSize: { width: 90, height: 30 },
        lpFontSize: 13,
        positions: {
          usd: { x: 0, y: 100 },
          plsStables: { x: 65, y: 100 },
          pls: { x: 199, y: 100 },
          plsPlsx: { x: 170, y: 25 },
          plsx1: { x: 295, y: 25 },
          plsInc: { x: 170, y: 175 },
          inc: { x: 295, y: 175 },
          plsxInc: { x: 360, y: 175 },
          plsx2: { x: 490, y: 175 },
        },
      };
    } else {
      return {
        nodeSize: { width: 40, height: 40 },
        fontSize: 14,
        lpSize: { width: 120, height: 40 },
        lpFontSize: 13,
        positions: {
          usd: { x: 0, y: 120 },
          plsStables: { x: 80, y: 120 },
          pls: { x: 260, y: 120 },
          plsPlsx: { x: 220, y: 30 },
          plsx1: { x: 370, y: 30 },
          plsInc: { x: 220, y: 210 },
          inc: { x: 370, y: 210 },
          plsxInc: { x: 450, y: 210 },
          plsx2: { x: 610, y: 210 },
        },
      };
    }
  };

  const styles = getStylesForSize();

  const getEdgeAnimation = (edgeIndex) => {
    const animatedEdges = {
      0: ['e1', 'e2'],
      1: ['e3', 'e4'],
      2: ['e5', 'e6'],
      3: ['e7', 'e8'],
    };
    return animatedEdges[index]?.includes(edgeIndex) || false;
  };

  const isNodeActive = (nodeId) => {
    const activeNodes = {
      0: ['usd', 'pls-stables', 'pls'],
      1: ['pls', 'pls-plsx', 'plsx1'],
      2: ['pls', 'pls-inc', 'inc'],
      3: ['inc', 'plsx-inc', 'plsx2'],
    };
    return activeNodes[index]?.includes(nodeId) || false;
  };


  const nodes = [
    {
      id: 'usd',
      type: 'input',
      data: { label: '$' },
      position: styles.positions.usd,
      style: { 
        background: '#4CAF50', 
        color: 'black', 
        borderRadius: '50%', 
        width: styles.nodeSize.width, 
        height: styles.nodeSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.fontSize + 2}px` 
      }
    },
    {
      id: 'pls-stables',
      data: { label: 'PLS_Stables LP' },
      position: styles.positions.plsStables,
      style: { 
        background: '#1e88e5', 
        color: 'white', 
        width: styles.lpSize.width, 
        height: styles.lpSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.lpFontSize}px`
      }
    },
    {
      id: 'pls',
      data: { label: 'PLS' },
      position: styles.positions.pls,
      style: { 
        border: '1px solid white', 
        color: 'black', 
        borderRadius: '50%', 
        width: styles.nodeSize.width, 
        height: styles.nodeSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.fontSize}px` 
      }
    },
    {
      id: 'pls-plsx',
      data: { label: 'PLS_PLSX LP' },
      position: styles.positions.plsPlsx,
      style: { 
        background: '#66bb6a', 
        color: 'white', 
        width: styles.lpSize.width, 
        height: styles.lpSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.lpFontSize}px`
      }
    },
    {
      id: 'plsx1',
      data: { label: 'PLSX' },
      position: styles.positions.plsx1,
      style: { 
        border: '1px solid white', 
        color: 'black', 
        borderRadius: '50%', 
        width: styles.nodeSize.width, 
        height: styles.nodeSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.fontSize}px` 
      }
    },
    {
      id: 'pls-inc',
      data: { label: 'PLS_INC LP' },
      position: styles.positions.plsInc,
      style: { 
        background: '#ef5350', 
        color: 'white', 
        width: styles.lpSize.width, 
        height: styles.lpSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.lpFontSize}px`
      }
    },
    {
      id: 'inc',
      data: { label: 'INC' },
      position: styles.positions.inc,
      style: { 
        border: '1px solid white', 
        color: 'black', 
        borderRadius: '50%', 
        width: styles.nodeSize.width, 
        height: styles.nodeSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.fontSize}px` 
      }
    },
    {
      id: 'plsx-inc',
      data: { label: 'PLSX_INC LP' },
      position: styles.positions.plsxInc,
      style: { 
        background: '#ff9800', 
        color: 'white', 
        width: styles.lpSize.width, 
        height: styles.lpSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.lpFontSize}px`
      }
    },
    {
      id: 'plsx2',
      type: 'output',
      data: { label: 'PLSX' },
      position: styles.positions.plsx2,
      style: { 
        border: '1px solid white', 
        color: 'black', 
        borderRadius: '50%', 
        width: styles.nodeSize.width, 
        height: styles.nodeSize.height, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontSize: `${styles.fontSize}px` 
      }
    },
  ].map(node => ({
    ...node,
    style: {
      ...node.style,
      background: isNodeActive(node.id) ? node.style.background : '#808080',
      color: isNodeActive(node.id) ? node.style.color : 'white',
      border: isNodeActive(node.id) ? node.style.border : '1px solid #808080',
    }
  }));

  const edges = [
    { id: 'e1', source: 'usd', target: 'pls-stables', type: 'smoothstep', animated: getEdgeAnimation('e1'), style: { stroke: getEdgeAnimation('e1') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e1') ? '#FFD700' : '#4CAF50' } },
    { id: 'e2', source: 'pls-stables', target: 'pls', type: 'smoothstep', animated: getEdgeAnimation('e2'), style: { stroke: getEdgeAnimation('e2') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e2') ? '#FFD700' : '#4CAF50' } },
    { id: 'e3', source: 'pls', target: 'pls-plsx', type: 'smoothstep', animated: getEdgeAnimation('e3'), style: { stroke: getEdgeAnimation('e3') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e3') ? '#FFD700' : '#4CAF50' } },
    { id: 'e4', source: 'pls-plsx', target: 'plsx1', type: 'smoothstep', animated: getEdgeAnimation('e4'), style: { stroke: getEdgeAnimation('e4') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e4') ? '#FFD700' : '#4CAF50' } },
    { id: 'e5', source: 'pls', target: 'pls-inc', type: 'smoothstep', animated: getEdgeAnimation('e5'), style: { stroke: getEdgeAnimation('e5') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e5') ? '#FFD700' : '#4CAF50' } },
    { id: 'e6', source: 'pls-inc', target: 'inc', type: 'smoothstep', animated: getEdgeAnimation('e6'), style: { stroke: getEdgeAnimation('e6') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e6') ? '#FFD700' : '#4CAF50' } },
    { id: 'e7', source: 'inc', target: 'plsx-inc', type: 'smoothstep', animated: getEdgeAnimation('e7'), style: { stroke: getEdgeAnimation('e7') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e7') ? '#FFD700' : '#4CAF50' } },
    { id: 'e8', source: 'plsx-inc', target: 'plsx2', type: 'smoothstep', animated: getEdgeAnimation('e8'), style: { stroke: getEdgeAnimation('e8') ? '#FFD700' : '#4CAF50', strokeWidth: 3 }, markerEnd: { type: 'arrowclosed', color: getEdgeAnimation('e8') ? '#FFD700' : '#4CAF50' } },
  ].map(edge => ({
    ...edge,
    animated: getEdgeAnimation(edge.id),
    style: {
      ...edge.style,
      stroke: getEdgeAnimation(edge.id) ? '#FFD700' : '#808080',
    },
    markerEnd: {
      ...edge.markerEnd,
      color: getEdgeAnimation(edge.id) ? '#FFD700' : '#808080',
    }
  }));

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      zoomOnScroll={false}
      zoomOnPinch={false}
      zoomOnDoubleClick={false}
      panOnScroll={false}
      panOnDrag={false}
      nodesDraggable={false}
      style={{ width: '100%', height: '280px' }} // Adjust height as needed
    />
  );
};


export default LPFlowDiagram;