import React, { useState, useRef, useCallback } from 'react';
import { Analytics } from "@vercel/analytics/react"
import './App.css';
import { FaPlus, FaMinus, FaInfoCircle, FaTwitter, FaTelegram, FaReddit, FaCoffee, FaChartLine } from 'react-icons/fa'; // Import the info icon
import LPFlowView from './components/LPFlowView.js';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip from MUI
import { TwitterShareButton, TelegramShareButton, RedditShareButton } from 'react-share';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function App() {
  const [inputs, setInputs] = useState([
    { ticker: 'PLSX', sacMoney: '450000000' }
  ]);
  const [useAdjustment, setUseAdjustment] = useState(false); // New state for checkbox
  const [adjustmentPercentage, setAdjustmentPercentage] = useState(0); // New state for adjustment percentage
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showLPFlow, setShowLPFlow] = useState(false);
  const [initialPricePLS, setInitialPricePLS] = useState(null);
  const [initialPricePLSX, setInitialPricePLSX] = useState(null);
  const [initialPriceINC, setInitialPriceINC] = useState(null);
  const [initialPriceHEX, setInitialPriceHEX] = useState(null);
  const [newPricePLS, setNewPricePLS] = useState(null);
  const [newPricePLSX, setNewPricePLSX] = useState(null);
  const [newPriceINC, setNewPriceINC] = useState(null);
  const [newPriceHEX, setNewPriceHEX] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState(true);

  const allTickers = ['PLSX', 'PLS', 'INC', 'HEX'];

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const handleAddInput = () => {
    if (inputs.length < 4) {
      const availableTickers = allTickers.filter(ticker => !inputs.some(input => input.ticker === ticker));
      setInputs([...inputs, { ticker: availableTickers[0], sacMoney: '0' }]);
    }
  };

  const handleRemoveInput = (index) => {
    if (inputs.length > 1) {
      const newInputs = inputs.filter((_, i) => i !== index);
      setInputs(newInputs);
    }
  };

  const handleInputChange = (index, field, value) => {
    const newInputs = [...inputs];
    newInputs[index][field] = value;
    setInputs(newInputs);
  };

  const calculateTotal = () => {
    return inputs.reduce((total, input) => total + parseFloat(input.sacMoney || 0), 0);
  };


  const handleCalculate = async () => {
    setLoading(true);
    setError(null);
    setDisclaimerVisible(false); // Minimize the disclaimer
    try {
      const sacMoneyString = inputs.map(input => input.sacMoney).join(',');
      const tickerString = inputs.map(input => input.ticker).join(',');
      const url = `${API_URL}/calculate?sacMoney=${sacMoneyString}&ticker=${tickerString}&adjustmentPercentage=${useAdjustment ? adjustmentPercentage : 0}`;
      console.log('Fetching from URL:', url);
      
      const response = await fetch(url);
      const data = await response.json();
      console.log('API Response:', data);
      setInitialPricePLS(data.originalPricesAndLPValues.pricePLS);
      setInitialPricePLSX(data.originalPricesAndLPValues.pricePLSX);
      setInitialPriceINC(data.originalPricesAndLPValues.priceINC);
      setInitialPriceHEX(data.originalPricesAndLPValues.priceHEX);
      setNewPricePLS(data.results[data.results.length - 1].newPricesAndLPValues.pricePLS);
      setNewPricePLSX(data.results[data.results.length - 1].newPricesAndLPValues.pricePLSX);
      setNewPriceINC(data.results[data.results.length - 1].newPricesAndLPValues.priceINC);
      setNewPriceHEX(data.results[data.results.length - 1].newPricesAndLPValues.priceHEX);
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid response from API');
      }
      
      // Check for expected properties and provide defaults if missing
      const results = {
        priceJumps: data.results?.priceJumps || {},
        newPricesAndLPValues: data.newPricesAndLPValues || {},
        originalPricesAndLPValues: data.originalPricesAndLPValues || {},
        ...data
      };
      
      setResults(results);
      console.log('Updated Results State:', results);
      console.log('Processed results:', results);
    } catch (error) {
      console.error('Error details:', error);
      setError(`Failed to fetch results: ${error.message}`);
      setResults(null);
    } finally {
      setLoading(false);
    }
  };

  const generateFlowElements = (data) => {
    console.log('Generating flow elements with data:', JSON.stringify(data, null, 2));
    const nodes = [];
    const edges = [];
    let xOffset = 0;
    let yOffset = 0;
    const xGap = 300;
    const yGap = 200;

    // Input node
    nodes.push({
      id: 'input',
      type: 'input',
      data: { 
        label: (
          <div>
            <strong>Input</strong>
            {data.inputs.map((input, index) => (
              <div key={index}>
                {input.ticker}: ${input.sacMoney}
              </div>
            ))}
          </div>
        )
      },
      position: { x: xOffset, y: yOffset },
      style: { width: 200, padding: 10 }
    });
    xOffset += xGap;

    // Initial Prices node
    if (data.inputPricesAndLPValues) {
      nodes.push({
        id: 'initialPrices',
        data: {
          label: (
            <div>
              <strong>Initial Prices</strong>
              {Object.entries(data.inputPricesAndLPValues).map(([ticker, values]) => (
                <div key={ticker}>
                  {ticker}: ${values.price.toFixed(6)}
                </div>
              ))}
            </div>
          )
        },
        position: { x: xOffset, y: yOffset },
        style: { width: 200, padding: 10 }
      });
      edges.push({ id: 'edge-input-initialPrices', source: 'input', target: 'initialPrices' });
      xOffset += xGap;
    }

    // LP Purchases
    if (data.results && data.results.lpPurchases) {
      data.results.lpPurchases.forEach((purchase, index) => {
        const nodeId = `lpPurchase-${index}`;
        nodes.push({
          id: nodeId,
          data: {
            label: (
              <div>
                <strong>LP Purchase {index + 1}</strong>
                <div>LP: {purchase.lp}</div>
                <div>Ticker: {purchase.ticker}</div>
                <div>Amount: ${purchase.purchaseAmount.toFixed(2)}</div>
              </div>
            )
          },
          position: { x: xOffset, y: yOffset },
          style: { width: 200, padding: 10 }
        });
        edges.push({ 
          id: `edge-lpPurchase-${index}`, 
          source: index === 0 ? 'initialPrices' : `lpPurchase-${index - 1}`, 
          target: nodeId 
        });
        xOffset += xGap;
      });
    }

    // Updated Prices node
    if (data.newPricesAndLPValues) {
      nodes.push({
        id: 'updatedPrices',
        type: 'output',
        data: {
          label: (
            <div>
              <strong>Updated Prices</strong>
              {Object.entries(data.newPricesAndLPValues).map(([ticker, values]) => (
                <div key={ticker}>
                  {ticker}: ${values.price.toFixed(6)}
                </div>
              ))}
            </div>
          )
        },
        position: { x: xOffset, y: yOffset },
        style: { width: 200, padding: 10 }
      });
      edges.push({ 
        id: 'edge-lastPurchase-updatedPrices', 
        source: `lpPurchase-${data.results.lpPurchases.length - 1}`, 
        target: 'updatedPrices' 
      });
    }

    // Price Jumps node
    if (data.results && data.results.priceJumps) {
      nodes.push({
        id: 'priceJumps',
        type: 'output',
        data: {
          label: (
            <div>
              <strong>Price Jumps</strong>
              {Object.entries(data.results.priceJumps).map(([ticker, jump]) => (
                <div key={ticker}>
                  {ticker}: {jump}x
                </div>
              ))}
            </div>
          )
        },
        position: { x: xOffset, y: yOffset + yGap },
        style: { width: 200, padding: 10 }
      });
      edges.push({ id: 'edge-updatedPrices-priceJumps', source: 'updatedPrices', target: 'priceJumps' });
    }

    return { nodes, edges };
  };

  // const coinNameMap = {
  //   pricePLS: 'PLS',
  //   pricePLSX: 'PLSX',
  //   priceINC: 'INC',
  //   priceHEX: 'HEX'
  // };

  const getNewPrice = (results, coin) => {
    // console.log('getNewPrice called with:', { results, coin });
    if (results && results.results && results.results[2] && results.results[2].newPrices) {
      const newPrice = results.results[2].newPrices[coin.replace('price', '')].price;
      // console.log('New price found:', newPrice);
      return newPrice;
    }
    // console.log('New price not found, returning null');
    return null;
  };

  const lpFlowRef = useRef(null);

  const handleShowLPFlow = () => {
    setShowLPFlow(true);
    setTimeout(() => {
      lpFlowRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const scenarios = [
    { label: '$400M Evenly', value: 400000000 },
    { label: '$400M HEX/PLSX', value: 400000000, type: 'hex-plsx' },
    { label: '$800M HEX/PLSX', value: 800000000, type: 'hex-plsx' },
  ];

  const handleScenarioSelect = (scenarioValue, scenarioType) => {
    if (selectedScenario === scenarioValue) {
      setSelectedScenario(null);
      setInputs([{ ticker: 'PLSX', sacMoney: '0' }]);
    } else {
      setSelectedScenario(scenarioValue);
      if (scenarioType === 'hex-plsx') {
        const perCoin = scenarioValue / 2;
        setInputs([
          { ticker: 'PLSX', sacMoney: perCoin.toString() },
          { ticker: 'HEX', sacMoney: perCoin.toString() },
        ]);
      } else {
        const perCoin = scenarioValue / 4;
        setInputs([
          { ticker: 'PLSX', sacMoney: perCoin.toString() },
          { ticker: 'PLS', sacMoney: perCoin.toString() },
          { ticker: 'INC', sacMoney: perCoin.toString() },
          { ticker: 'HEX', sacMoney: perCoin.toString() },
        ]);
      }
    }
  };

  const generateShareText = () => {
    if (!results) return '';
    
    const totalSac = calculateTotal();
    let formattedTotalSac;
    if (totalSac >= 1e9) {
      formattedTotalSac = (totalSac / 1e9).toFixed(1) + 'B';
    } else if (totalSac >= 1e6) {
      formattedTotalSac = (totalSac / 1e6).toFixed(1) + 'M';
    } else if (totalSac >= 1e3) {
      formattedTotalSac = (totalSac / 1e3).toFixed(1) + 'K';
    } else {
      formattedTotalSac = totalSac.toFixed(0);
    }

    // Convert new prices to bold using Unicode characters
    const boldNewPricePLS = `${Number(newPricePLS).toFixed(5)}`;
    const boldNewPricePLSX = `${Number(newPricePLSX).toFixed(5)}`;
    const boldNewPriceINC = `${Number(newPriceINC).toFixed(2)}`;
    const boldNewPriceHEX = `${Number(newPriceHEX).toFixed(4)}`;

    return `I simulated a $${formattedTotalSac} purchase of ${inputs.map(input => input.ticker).join(', ')}. Results:\n\n` +
           `Coin   | Initial | New\n` +
           `-------------------------\n` +
           `PLS    | ${Number(initialPricePLS).toFixed(6)} | ${boldNewPricePLS}\n` +
           `PLSX   | ${Number(initialPricePLSX).toFixed(6)} | ${boldNewPricePLSX}\n` +
           `INC    | ${Number(initialPriceINC).toFixed(2)} | ${boldNewPriceINC}\n` +
           `HEX    | ${Number(initialPriceHEX).toFixed(4)} | ${boldNewPriceHEX}\n\n` +
           `Try Roonskies app:`;
  };

  const handleCopyAddress = useCallback(() => {
    navigator.clipboard.writeText('0x7e18d2E1ECdAd508AD750125d5Ed8538d70B94ed')
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="desktop-title">Price Appreciation Simulator</h1>
      </header>
      <div className="input-container">
        {inputs.map((input, index) => (
          <div key={index} className="input-row">
            <div className="input-group ticker">
              <label>
                Ticker:
                <select 
                  value={input.ticker} 
                  onChange={(e) => handleInputChange(index, 'ticker', e.target.value)}
                >
                  {allTickers.map(ticker => (
                    <option 
                      key={ticker} 
                      value={ticker}
                      disabled={inputs.some((i, idx) => i.ticker === ticker && idx !== index)}
                    >
                      {ticker}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="input-group sac-money">
              <label>
                Pulsechain Reserve - Sac ($):
                <input
                  type="text"
                  value={input.sacMoney}
                  onChange={(e) => handleInputChange(index, 'sacMoney', e.target.value)}
                />
              </label>
              <div className="button-container">
                <button onClick={() => handleRemoveInput(index)} className="remove-btn" disabled={inputs.length === 1}>
                  <FaMinus />
                </button>
                <button onClick={handleAddInput} className="add-btn" disabled={inputs.length >= 4 || index !== inputs.length - 1}>
                  <FaPlus />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="chip-adjustment-container">
        <Stack direction="row" spacing={1} justifyContent="center" mb={2}>
          {scenarios.map((scenario) => (
            <Chip
              key={scenario.value + (scenario.type || '')}
              label={scenario.label}
              onClick={() => handleScenarioSelect(scenario.value, scenario.type)}
              color={selectedScenario === scenario.value ? "primary" : "default"}
              sx={{
                backgroundColor: selectedScenario === scenario.value ? undefined : '#808080',
                color: 'white',
                '&:hover': {
                  backgroundColor: selectedScenario === scenario.value ? undefined : '#6a6a6a',
                },
              }}
            />
          ))}
        </Stack>
        <div className="adjustment-percentage">
          <FormControlLabel
            control={
              <Checkbox
                checked={useAdjustment}
                onChange={(e) => setUseAdjustment(e.target.checked)}
                sx={{ color: 'white' }}
              />
            }
            label="Liquidity Removal Simulator"
            sx={{ color: 'white', marginRight: 2 }}
          />
          <Tooltip
            title={
              <span style={{ fontSize: '1.2em' }}>
                Selecting this simulates the Liquidity removal by providers who are losing on Impermanent Loss. The number you select will be the percentage removed every time the pair doubles in ratio. In a recent sample, when HEX gained 2X against WPLS, we found 7.17% LP removed. Around the 3X gain, about 25% LP was removed. Thus we've provided a slider and an option for you to speculate yourself, responsibly.
              </span>
            }
            arrow
          >
            <span>
              <FaInfoCircle style={{ color: 'white', cursor: 'pointer', marginRight: 20 }} />
            </span>
          </Tooltip>
          {useAdjustment && (
            <Slider
              value={adjustmentPercentage}
              onChange={(e, newValue) => setAdjustmentPercentage(newValue)}
              aria-labelledby="adjustment-percentage-slider"
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0}
              max={25}
              sx={{ width: '150px', marginLeft: '10px' }}
            />
          )}
        </div>
      </div>
      <div className="total">
        Total: ${calculateTotal().toLocaleString()}
      </div>
      <button onClick={handleCalculate} disabled={loading} className="calculate-btn">
        {loading ? 'Calculating...' : 'Calculate'}
      </button>
      {error && <p className="error">{error}</p>}
      {results && (
        <div className="results">
          <h2>Results</h2>
          <h3>Pulsechain Reserve - ETH (Sac): ${Number(inputs.reduce((sum, input) => sum + Number(input.sacMoney), 0)).toLocaleString()}</h3>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Coin</th>
                  <th>Initial Price</th>
                  <th>New Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PLS</td>
                  <td>${Number(initialPricePLS).toFixed(6)}</td>
                  <td>${Number(newPricePLS).toFixed(5)}</td>
                  <td>{(newPricePLS / initialPricePLS).toFixed(2)}X</td>
                </tr>
                <tr>
                  <td>PLSX</td>
                  <td>${Number(initialPricePLSX).toFixed(6)}</td>
                  <td>${Number(newPricePLSX).toFixed(5)}</td>
                  <td>{(newPricePLSX / initialPricePLSX).toFixed(2)}X</td>
                </tr>
                <tr>
                  <td>INC</td>
                  <td>${Number(initialPriceINC).toFixed(2)}</td>
                  <td>${Number(newPriceINC).toFixed(2)}</td>
                  <td>{(newPriceINC / initialPriceINC).toFixed(2)}X</td>
                </tr>
                <tr>
                  <td>HEX</td>
                  <td>${Number(initialPriceHEX).toFixed(4)}</td>
                  <td>${Number(newPriceHEX).toFixed(4)}</td>
                  <td>{(newPriceHEX / initialPriceHEX).toFixed(2)}X</td>
                </tr>
              </tbody>
            </table>
          <div className="share-buttons">
            <TwitterShareButton url="https://pampi.vercel.app/" title={generateShareText()}>
              <button className="share-btn twitter">
                <FaTwitter />  Share on X
              </button>
            </TwitterShareButton>
            <TelegramShareButton url="https://pampi.vercel.app/" title={generateShareText()}>
              <button className="share-btn telegram">
                <FaTelegram />
              </button>
            </TelegramShareButton>
            <RedditShareButton url="https://pampi.vercel.app/" title={generateShareText()}>
              <button className="share-btn reddit">
                <FaReddit />
              </button>
            </RedditShareButton>
        </div>            

          </div>
          <button className="show-lp-flow" onClick={handleShowLPFlow}>
            Show me how
          </button>
        </div>
      )}

      {/* Disclaimer Section */}
      {disclaimerVisible && (
        <div className="disclaimer">
          <button className="close-btn" onClick={() => setDisclaimerVisible(false)}>X</button>
          <h3>Disclaimer</h3>
          <p>
          Please interpret the numbers with caution, as they do not account for market buy/sell pressures. This app is designed to illustrate price reflexivity. In the coming weeks, we will enhance the "Show me How" section to delve deeper into the "Why" and "How" of reflexivity among RH tickers. Focusing on these insights will help us collectively gain a better understanding of the price dynamics.
          </p>
        </div>
      )}

      {showLPFlow && results && (
        <div ref={lpFlowRef}>
          <LPFlowView
            onClose={() => setShowLPFlow(false)}
            data={results}
            inputs={inputs} // Pass inputs as a prop
          />
        </div>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Address copied to clipboard!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <footer className="social-footer">
        <div className="social-links">
          <a href="https://twitter.com/@Roonskies93" target="_blank" rel="noopener noreferrer" className="social-link">
            <FaTwitter />
          </a>
          <a href="https://t.me/Roonskies" target="_blank" rel="noopener noreferrer" className="social-link">
            <FaTelegram />
          </a>
        </div>
      </footer>

      {/* Add the Analytics component here, at the end of your App component */}
      <Analytics />
    </div>
  );
}

export default App;
